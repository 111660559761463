<template>
  <div class="session">
    <v-lazy v-model="isActive" :options="{
      threshold: .5
    }" height="800" transition="fade-transition">
      <v-container>
        <v-row justify="center" class="mt-12">
          <v-img :src="appLogo" max-width="280"></v-img>
        </v-row>
        <v-row justify="center" class="mt-8">
          <h2 class="mb-4 primary--text">Tu contraseña fue establecida, puedes cerrar esta ventana e iniciar sesión en
            la aplicación móvil.</h2>
        </v-row>
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </template>
      </v-container>
    </v-lazy>
  </div>
</template>

<script>
import AppConfig from '@/constants/app-config'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
  created() {
    window.location.hash = 'no-back-button'
    window.location.hash = 'Again-No-back-button' // chrome
    window.onhashchange = function () { window.location.hash = '' }
  },
  components: {
  },
  data() {
    return {
      isActive: false,
      error: null,
      appLogo: AppConfig.appLogo
    }
  },
  methods: {
  }
}
</script>
<style>
.sub-text {
  width: 296px;
  font-size: 11px;
}
</style>
